:root {
  --off-white: #fafafa;
}

.nav {
  background-color: var(--off-white);
  position: sticky;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.nav-logo {
  height: 70px;
  width: 70px;
}

.nav-logo:hover {
  cursor: pointer;
}

.nav .nav-content {
  max-width: 900px;
  padding: 0rem 3rem;
  margin: 0 auto;
  display:flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-item {
  display:inline-flex;
  margin-top: 0.75em;
  margin-left: 2rem;
  color: #333;
}

.nav-item:hover {
  color: black;
  cursor: pointer;
}

.titles {
  font-size: medium;
}

.section-dark {
  background: #333;
  color: white;
}

.section-content {
  max-width: 100%;
  margin: 0 auto;
  padding: 40px;
}

.about-content {
  max-width: 90%;
  margin: 0 auto;
  text-align: justify;
  /* padding: 40px; */
}

.timeline-content {
  box-shadow: 30px 30px 30px 30px rgba(0,0,0,.1);
  max-width: 100%;
  margin: 0 auto;
  /* padding: 40px;  */
  /* background: #c5c3ac; */
  /* background: #acbdc5; */
  /* background: #5ca9cf; */
  background: #5ca9cf;
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: 'Ranchers', cursive; */
  /* font-family: "Montserrat"; */
  /* font-family: 'Akaya Telivigala', cursive; */
  font-size: 16px;
  color: rgb(53, 53, 53);
}

h1 {
  /* padding-top: 0.25em; */
  font-size: xx-large;
  color: rgb(53, 53, 53);
  font-family:'Times New Roman', Times, serif;
  text-align: center;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.h3Class {
  font-size:x-large;
  color: rgb(53, 53, 53);
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.projectCards {
  color: rgb(53, 53, 53);
  /* font-family: "Montserrat", sans-serif; */
  text-align:center;
  /* padding-top: 0.25em;
  padding-bottom: 0.25em; */
}

.projectText{
  text-align: justify;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 1em 3em !important;
}

.vertical-timeline-element-title {
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
  font-size: large;
}

.vertical-timeline-element-subtitle {
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
  /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
  font-size: medium;
}

.cardClass {
  max-width: 100%;
  margin: 0 auto;
  /* text-align: center; */
  /* padding: 40px; */
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.cardClass:hover {
  cursor: pointer;
  /* transform: scale(1.05); */
  /* box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02); */
}

.cardClass1 {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  /* padding: 5px; */
  box-shadow: 0 0.12em 0.25em 0 rgba(0, 0, 0, 0.25), 0 0.2em 0.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 1.0em 2.1em !important;
}

.cardClass2 {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  /* padding: 5px; */
  box-shadow: 0 0.12em 0.25em 0 rgba(0, 0, 0, 0.25), 0 0.2em 0.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 1.0em 1.1em !important;
}
.cardClass2:hover {
  cursor: pointer;
  /* transform: scale(1.05); */
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.cardClass1:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.date {
  color: rgb(23, 24, 24);
}

#description {
  margin: 1.5em 0 2em 0;
}

.social{
  text-align: center;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
  .cardClass1{
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
  nav{
    width: 100%;
    float: none;
}

nav {
    margin-right: 0;
    margin-bottom: 1em;
}
}

@media only screen and (max-width: 550px) {
  nav{
      width: 100%;
      float: none;
  }
  .cardClass1{
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
    height: 100%;
  }

  nav {
      margin-right: 0;
      margin-bottom: 1em;
  }
}